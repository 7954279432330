import { makeAutoObservable } from "mobx";
import { AxiosError } from "axios";
import userStore from "store/User";
import { authorizedRequest } from "../../../utils/authorization";
import connectSources from "../../../store/ConnectSources";
import appStore from "../../../store/App";

class Connect {
  constructor() {
    makeAutoObservable(this);
  }

  fields: string[] = [];

  fieldLabels: { [key: string]: string } = {};

  instructionUrl: string = "";

  account: {
    id: string;
    name: string;
    logo: string;
    code: string | null;
  } | null = null;

  file: { name: string; value: string | ArrayBuffer | null; size: number } = {
    name: "",
    value: "",
    size: 0,
  };

  values: { [key: string]: string } = {};

  uploading = false;

  reset = () => {
    this.account = null;

    this.fields = [];

    this.file = {
      name: "",
      value: "",
      size: 0,
    };

    this.values = {};

    this.uploading = false;
  };

  getFields = async (code: string) => {
    const res = await authorizedRequest({
      service: "pds",
      url: `/v1/stores/credentials/${code}`,
    });
    this.fields = res.required;
    this.fieldLabels = res.properties.labels.default;
    this.instructionUrl = res.properties.instructionUrl.default;
    this.fields.forEach((field) => {
      this.values[field] = "";
    });
  };

  getAccountById = async (id: string) => {
    try {
      await authorizedRequest({
        service: "pds",
        url: "/v1/accounts",
        method: "POST",
        data: {
          storeId: id,
          companyId: userStore.user?.company.id || "",
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  addAccountByIds = async (ids: string[]) => {
    try {
      this.uploading = true;
      await Promise.all([ids.map((id) => this.getAccountById(id))]);
    } catch (e) {
      console.error(e);
    } finally {
      this.uploading = false;
    }
  };

  addAccount = async () => {
    this.uploading = true;
    let data: { credentials: {}; storeId?: string; companyId?: string };
    if (this.fields.includes("key")) {
      data = {
        storeId: this.account?.id || "",
        companyId: userStore.user?.company.id || "",
        credentials: {
          ...this.values,
          key: this.file.value,
        },
      };
    } else {
      data = {
        storeId: this.account?.id || "",
        companyId: userStore.user?.company.id || "",
        credentials: this.values,
      };
    }

    try {
      let method = "POST";
      if (
        this.account?.id &&
        connectSources.existingAccounts[this.account?.id]
      ) {
        method = "PATCH";
        data = {
          credentials: data.credentials,
        };
      }

      let id;

      if (this.account?.id) {
        id = connectSources.existingAccounts[this.account.id]?.id;
      }

      await authorizedRequest({
        service: "pds",
        url: method === "PATCH" && id ? `/v1/accounts/${id}` : "/v1/accounts",
        method,
        data,
      });

      await connectSources.getAccounts();
    } catch (e: unknown) {
      console.log(e);
      if (e instanceof AxiosError) {
        if (e?.response?.status === 480) {
          appStore.setNotification({
            type: "error",
            title: `An error occurred while attempting to connect to ${
              this?.account?.name ?? "Account"
            }`,
            message:
              "Please check the connection keys and enter them once again.",
            width: 380,
          });
        }
        if (e?.response?.status === 524) {
          appStore.setNotification({
            type: "error",
            title: `${this?.account?.name} connection timeout has expired. Please try to connect once again.`,
            width: 380,
          });
        }
      }
    } finally {
      this.uploading = false;
    }
  };

  get canSend() {
    if (this.uploading) return false;
    for (const val in this.values) {
      if (!this.values[val].trim() && val !== "key") return false;
    }
    return !(this.fields.includes("key") && !this.file.value);
  }
}

const accountConnect = new Connect();
export default accountConnect;
