import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import classNames from "classnames";
import { EUR, USD } from "const";
import { ReactComponent as Logo } from "assets/mainLogo.svg";
import { Currency } from "types";
import { commonIntl, getCurrencySymbol } from "utils/helpers";
import Auth from "../../store/Auth";
import userStore from "../../store/User";
import accountsStore from "../../store/Accounts";
import { authorizedRequest } from "../../utils/authorization";
import connectSources from "store/ConnectSources";
import applaudStore from "store/Applaud";

import { ReactComponent as Home } from "./assets/home.svg";
import { ReactComponent as PieChart } from "./assets/pie_chart.svg";
import { ReactComponent as Logout } from "./assets/logout.svg";
import { ReactComponent as ChevronTop } from "./assets/chevron_top.svg";
import { ReactComponent as Transactions } from "./assets/transactions.svg";
import { ReactComponent as Source } from "./assets/source.svg";
import { ReactComponent as Calendar } from "./assets/calendar.svg";
import { ReactComponent as Report } from "./assets/report.svg";
import { ReactComponent as Ao } from "./assets/ao_classic.svg";
import styles from "./styles.module.scss";
import ReconcilationReport from "pages/ReconcilationReport";

const SideMenu = observer(() => {
  const params = useLocation();
  const CURRENT_VERSION = process.env.REACT_APP_VERSION;
  const hasRevenueSource = connectSources.hasRevenueSource;
  const hasDoneOnboarding = !["in-review", "new"].includes(
    //@ts-ignore
    userStore.me?.company?.status
  );
  const isActiveAlready = userStore.me?.company?.status === "active";

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Logo className={styles.logo} />
        <div className={styles.versionText}>v. {CURRENT_VERSION}</div>
      </div>
      <div className={styles.list}>
        {/* {applaudStore.isOnlyClassicAvailable &&
        userStore.me?.status !== Status.new &&
        userStore.me?.status !== Status.rejected ? null : ( */}
        <Link
          to={hasRevenueSource || isActiveAlready ? "/" : "#"}
          className={`${styles.listItem}${
            params.pathname === "/"
              ? ` ${styles.active}`
              : hasRevenueSource
              ? ""
              : ` ${styles.disabled}`
          }`}
        >
          <Home /> <span>Dashboard</span>
        </Link>
        {/* )} */}
        <Link
          to={hasRevenueSource || isActiveAlready ? "/transactions" : "#"}
          className={`${styles.listItem}${
            params.pathname === "/transactions"
              ? ` ${styles.active}`
              : hasRevenueSource
              ? ""
              : ` ${styles.disabled}`
          }`}
        >
          <Transactions /> <span>Transactions</span>
        </Link>
        {(applaudStore.isClassicAvailable && (
          <Link
            to={hasRevenueSource || isActiveAlready ? "/ao-list" : "#"}
            className={`${styles.listItem}${
              params.pathname === "/ao-list" ? ` ${styles.active}` : ""
            }`}
          >
            <Ao /> <span>Activation Orders</span>
          </Link>
        )) ||
          null}
        <Link
          to={hasRevenueSource || isActiveAlready ? "/reporting-docs" : "#"}
          className={`${styles.listItem}${
            params.pathname === "/reporting-docs"
              ? ` ${styles.active}`
              : hasRevenueSource
              ? ""
              : ` ${styles.disabled}`
          }`}
        >
          <PieChart /> <span>Reporting Documents</span>
        </Link>
        {(applaudStore.isClassicExist && (
          <Link
            to="/expected-payments"
            className={`${styles.listItem}${
              params.pathname === "/expected-payments"
                ? ` ${styles.active}`
                : ""
            }`}
          >
            <Calendar /> <span>Expected Payments</span>
          </Link>
        )) ||
          null}
        {!applaudStore.isOnlyClassicAvailable && (
          <Link
            to={hasDoneOnboarding ? "/revenue_sources" : "#"}
            className={`${styles.listItem}${
              params.pathname === "/revenue_sources"
                ? ` ${styles.active}`
                : hasDoneOnboarding
                ? ""
                : ` ${styles.disabled}`
            }`}
          >
            <Source /> <span>Revenue Sources</span>
          </Link>
        )}
        <Link
          to={hasDoneOnboarding ? "/reconciliationReport" : "#"}
          className={`${styles.listItem}${
            params.pathname === "/reconciliationReport"
              ? ` ${styles.active}`
              : hasDoneOnboarding
              ? ""
              : ` ${styles.disabled}`
          }`}
        >
          <Report />
          <span>Reconciliation report</span>
        </Link>
        <WannaPlayBtn />
      </div>
    </div>
  );
});

const WannaPlayBtn = observer(() => {
  const [isOpen, setIsOpen] = useState(false);

  const getBalance = (currency: Currency) => {
    const balance = accountsStore.getAccountBalance[currency];
    const hasMinus = balance < 0;

    return (
      <span
        className={classNames(
          styles.amount,
          hasMinus && styles.amountWithMinus
        )}
      >
        {hasMinus && <span className={styles.minus}>−</span>}
        <span className={styles.currency}>{getCurrencySymbol(currency)}</span>
        {commonIntl.format(Math.abs(balance))}
      </span>
    );
  };

  useEffect(() => {
    if (!userStore.user?.company?.name) {
      userStore.getUserInfo();
    }
  }, []);
  useEffect(() => {
    if (userStore.user?.company?.id)
      accountsStore.getAccounts(userStore.user.company.id);
  }, [userStore.user?.company?.id]);
  if (!userStore.user?.company?.name) return null;
  const eurBalance = accountsStore.getAccountBalance[EUR]
    ? getBalance(EUR)
    : false;
  const usdBalance = accountsStore.getAccountBalance[USD]
    ? getBalance(USD)
    : false;
  const isBalanceExist =
    accountsStore.getAccountBalance[EUR] ||
    accountsStore.getAccountBalance[USD];
  return (
    <div className={styles.wannaPlayContainer}>
      <button
        className={styles.wannaPlayHolder}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.avatar}>
          {userStore.user.company.name.slice(0, 1)}
        </div>
        <div className={styles.companyLabel}>{userStore.user.company.name}</div>
        <div className={`${styles.topBtn} ${isOpen ? styles.opened : ""}`}>
          <ChevronTop />
        </div>
      </button>
      {isOpen && (
        <div className={styles.openedList}>
          <div className={styles.name}>Welcome, {userStore.user.username}</div>
          {(isBalanceExist && (
            <div className={styles.balance}>
              <span className={styles.title}>Balance Payable:</span>
              {accountsStore.isLoading ? "Loading EUR balance" : eurBalance}
              {accountsStore.isLoading ? "Loading EUR balance" : usdBalance}
            </div>
          )) ||
            null}
          <div className={styles.dividerLogout} />
          <button
            className={styles.logout}
            onClick={async () => {
              await authorizedRequest({
                url: "/v1/auth/logout",
                method: "POST",
                service: "fca",
                data: {
                  refresh_token: Auth.refreshToken,
                },
              });
              window.location.href = "/";
            }}
          >
            <Logout className={styles.logoutIcon} />
            <span className={styles.logOutText}>Log out</span>
          </button>
        </div>
      )}
    </div>
  );
});

export default SideMenu;
