import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import User from "store/User";
import SplittedButtons, {
  SplittedButton,
} from "../../../components/SplittedButton";
import applaudStore, { StatusClaim } from "../../../store/Applaud";
import { BlockTitle } from "../../../components/Text";
import FutureReceivables from "../../FutureReceivables";
import dashboardStore from "../../../store/Dashboard";
import { useFilterFunction } from "../../../components/SearchSelect/hooks";
import SearchSelect, {
  SearchSelectItem,
} from "../../../components/SearchSelect";
import ColorPass from "../../../components/SearchSelect/ColorPass";
import { EUR, USD } from "../../../const";
import NowFunding from "../../../components/NowFunding";
import style from "./styles.module.scss";

const SalesInfo: React.FC = observer(() => {
  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    dashboardStore.nowOrFuture = applaudStore.nowOrFuture;
  }, [applaudStore.nowOrFuture]);

  const { filterData, filteredItems, search } = useFilterFunction({
    items: [
      { value: USD, label: USD },
      { value: EUR, label: EUR },
    ],
  });

  return (
    <>
      <div className={style.topTable}>
        <div>
          <BlockTitle>Funding</BlockTitle>
        </div>
        <div className={style.btnsHolder}>
          {applaudStore.isNowAndFeatureAvailable && (
            <div className={style.typeBtns}>
              <SplittedButtons>
                <SplittedButton
                  onClick={() => {
                    dashboardStore.nowOrFuture = "NOW";
                  }}
                  isActive={dashboardStore.nowOrFuture === "NOW"}
                >
                  NOW
                </SplittedButton>
                <SplittedButton
                  isActive={dashboardStore.nowOrFuture === "FRE"}
                  onClick={() => {
                    dashboardStore.nowOrFuture = "FRE";
                  }}
                >
                  FUTURE
                </SplittedButton>
              </SplittedButtons>
            </div>
          )}
          <SplittedButtons>
            <SplittedButton
              onClick={() => {
                applaudStore.statusClaim = StatusClaim.FUNDED;
              }}
              isActive={applaudStore.statusClaim === StatusClaim.FUNDED}
            >
              FUNDED
            </SplittedButton>
            <SplittedButton
              isActive={applaudStore.statusClaim === StatusClaim.REPAID}
              onClick={() => {
                applaudStore.statusClaim = StatusClaim.REPAID;
              }}
            >
              REPAID
            </SplittedButton>
            <SplittedButton
              isActive={applaudStore.statusClaim === StatusClaim.REJECTED}
              onClick={() => {
                applaudStore.statusClaim = StatusClaim.REJECTED;
              }}
            >
              REJECTED
            </SplittedButton>
          </SplittedButtons>

          {
            (applaudStore.isUsdEurAvailable && (
              <div className={style.currencyBtns}>
                <SearchSelect
                  title={dashboardStore.fundingCurrency?.label || "All"}
                  onFilter={filterData}
                  setOpened={setOpened}
                  isOpened={isOpened}
                  label="Currency"
                >
                  {filteredItems.length > 1 && (
                    <SearchSelectItem
                      onClick={() => {
                        dashboardStore.fundingCurrency = null;
                        setOpened(false);
                      }}
                      selected={dashboardStore.fundingCurrency === null}
                      key="allRevenueSources"
                    >
                      <ColorPass splitText={search}>All</ColorPass>
                    </SearchSelectItem>
                  )}
                  {filteredItems.map((account) => {
                    return (
                      <SearchSelectItem
                        onClick={() => {
                          dashboardStore.fundingCurrency = {
                            // @ts-ignore
                            value: account.value,
                            label: account.label,
                          };
                          setOpened(false);
                        }}
                        selected={
                          account.value ===
                          dashboardStore.fundingCurrency?.label
                        }
                        key={account.label}
                      >
                        <ColorPass splitText={search}>
                          {account.label}
                        </ColorPass>
                      </SearchSelectItem>
                    );
                  })}
                </SearchSelect>
              </div>
            )) ||
              null
            // <div className={style.currencyBtns}>
            //   <SplittedButtons>
            //     <SplittedButton
            //       onClick={() => {
            //         dashboardStore.fundingCurrency = { label: 'EUR', value: 'EUR' };
            //       }}
            //       isActive={dashboardStore.fundingCurrency?.value === 'EUR'}
            //     >EUR
            //     </SplittedButton>
            //     <SplittedButton
            //       onClick={() => {
            //         dashboardStore.fundingCurrency = { label: 'USD', value: 'USD' };
            //       }}
            //       isActive={dashboardStore.fundingCurrency?.value === 'USD'}
            //     >USD
            //     </SplittedButton>
            //   </SplittedButtons>
            // </div>
          }
        </div>
      </div>
      {/*  <Select title="Select Platform" height={40} fontSize={16} width={240} margin="0 20px 0 auto">*/}
      {/*    <SelectItem onClick={() => { console.log('a'); }} height={40} fontSize={16}>*/}
      {/*      Apple*/}
      {/*    </SelectItem>*/}
      {/*    <SelectItem onClick={() => { console.log('b'); }} height={40} fontSize={16}>*/}
      {/*      Android*/}
      {/*    </SelectItem>*/}
      {/*    <SelectItem onClick={() => { console.log('c'); }} height={40} fontSize={16}>*/}
      {/*      Amazon*/}
      {/*    </SelectItem>*/}
      {/*  </Select>*/}

      {/*  <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
      {/*    <DesktopDatePicker*/}
      {/*      label="Custom input"*/}
      {/*      value=""*/}
      {/*      components={{ OpenPickerIcon: Calendar }}*/}
      {/*      onChange={(newValue) => {*/}
      {/*        console.log(newValue);*/}
      {/*      }}*/}
      {/*      renderInput={({ inputRef, inputProps, InputProps }) => (*/}
      {/*        <div className={style.dateInputHolder}>*/}
      {/*          <div className={style.datePickerContent}>*/}
      {/*            /!* eslint-disable-next-line jsx-a11y/label-has-associated-control *!/*/}
      {/*            <label className={style.datePickerLabel} htmlFor="from">Date from</label>*/}
      {/*            <input ref={inputRef} {...inputProps} className={style.dateInput} name="from" id="from" placeholder="" />*/}
      {/*          </div>*/}

      {/*          {InputProps?.endAdornment}*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </LocalizationProvider>*/}
      {/*  <div className={style.dateTo}>*/}
      {/*    <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
      {/*      <DesktopDatePicker*/}
      {/*        label="Custom input"*/}
      {/*        value=""*/}
      {/*        components={{ OpenPickerIcon: Calendar }}*/}
      {/*        onChange={(newValue) => {*/}
      {/*          console.log(newValue);*/}
      {/*        }}*/}
      {/*        renderInput={({ inputRef, inputProps, InputProps }) => (*/}
      {/*          <div className={style.dateInputHolder}>*/}
      {/*            <div className={style.datePickerContent}>*/}
      {/*              /!* eslint-disable-next-line jsx-a11y/label-has-associated-control *!/*/}
      {/*              <label className={style.datePickerLabel} htmlFor="to">Date to</label>*/}
      {/*              <input ref={inputRef} {...inputProps} className={style.dateInput} name="to" id="to" placeholder="" />*/}
      {/*            </div>*/}

      {/*            {InputProps?.endAdornment}*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    </LocalizationProvider>*/}
      {/*  </div>*/}
      {/*  <button className={style.downloaddownload} />*/}
      {/*</div>*/}

      {(dashboardStore.nowOrFuture === "NOW" && <NowFunding />) ||
        (dashboardStore.nowOrFuture === "FRE" && <FutureReceivables />)}

      {/*{*/}
      {/*  applaudStore.claims?.claims?.total === 0 &&*/}
      {/*    <div className={style.emptyPlaceholder}>*/}
      {/*      <EmptyClaims />*/}
      {/*      <div className={style.emptyText}>Data will be shown after creating the first purchase request</div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*{*/}
      {/*  applaudStore.claims?.sum_purchased && applaudStore.claims?.sum_purchase_price &&*/}
      {/*  <div className={style.totalBlock}>*/}
      {/*    <span className={style.totalText}><b>Total:</b></span>*/}
      {/*    <span className={`${style.text} ${style.bottomText}`}>Sales:&nbsp;</span>*/}
      {/*    <span className={style.totalAmount}>*/}
      {/*      <b>*/}
      {/*        {applaudStore.claims?.sum_purchase_price && `${formatCurrency(+applaudStore.claims?.sum_purchased, dashboardStore.fundingCurrency?.value)}` || <Skeleton width={103} height={16} />}*/}
      {/*      </b>*/}
      {/*    </span>*/}
      {/*    <span className={`${style.text} ${style.bottomText}`}>Advance:&nbsp;</span>*/}
      {/*    <span className={style.totalAmount}>*/}
      {/*      <b>*/}
      {/*        {applaudStore.claims?.sum_purchased && `${formatCurrency(+applaudStore.claims?.sum_purchase_price, dashboardStore.fundingCurrency?.value)}` || <Skeleton width={112} height={16} />}*/}
      {/*      </b>*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*}*/}
    </>
  );
});

export default SalesInfo;
