//@ts-nocheck
import React, { useEffect } from "react";
import { Route, Router, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import AuthRequired from "./components/AuthRequired";
import "@gamesb42/ui-kit/dist/const.css";
import "@gamesb42/ui-kit/dist/antd.css";
import "@gamesb42/ui-kit/dist/main.css";
import "./global.css";
import Auth from "./store/Auth";
import applaudStore, { Status } from "./store/Applaud";
import Home from "./pages/Home";
import ReportingDocuments from "./pages/ReportingDocuments";
import Transactions from "./pages/Transactions";
import Onboarding from "./pages/Onboarding";
import Connection from "./pages/Connection";
import ExpectedPayments from "./pages/ExpectedPayments";
import RevenueSources from "./pages/RevenueSources";
import Aolist from "./pages/Aolist";
import userStore from "./store/User";
import connectSources from "./store/ConnectSources";
import { about } from "./pages/Connection/components/AboutCompany";
import appStore from "./store/App";
import platformStore from "./pages/Home/SalesInfo/store";
import { appInfoInst } from "pages/Connection/components/AppInfo";
import { corparateDoc } from "pages/Connection/components/CorporateDocument";
import { companySignatoryInst } from "pages/Connection/components/CompanySignatory";
import { contactPersonInst } from "pages/Connection/components/ContactPerson";
import { director } from "pages/Connection/components/Director";
import ReconcilationReport from "pages/ReconcilationReport";

export const PAGES = {
  FUTURE_RECEIVABLES: "/future_receivables",
};

declare global {
  interface Window {
    ENV: any;
  }
}

const AuthHOC = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return <AuthRequired>{children}</AuthRequired>;
};

function App() {
  const history = useHistory();
  window.ENV = process.env;

  useEffect(() => {
    (async () => {
      Auth.authorize();
      if (Auth.scaToken) {
        try {
          await applaudStore.getAvailableProducts();
          await userStore.getUserInfo();
          await userStore.getMe();
          await platformStore.getPlatformsData();
          await connectSources.getAccounts();
          await connectSources.getSources();
          await about.getCompanyInfo();
          await applaudStore.getApplaud();
          await appInfoInst.getData(userStore.me?.company?.id.toString() || "");
          await corparateDoc.getCorporateDocData(
            userStore.me?.company?.id.toString() || ""
          );
          await director.getDirectorData(
            userStore.me?.company?.id.toString() || ""
          );

          companySignatoryInst.init(about?.signer);
          contactPersonInst.init(about?.contact);

          if (["in-review", "new"].includes(userStore.me?.company?.status)) {
            return history.push("/");
          }

          if (
            !connectSources.hasRevenueSource &&
            userStore.me?.company?.status !== "active"
          ) {
            return history.push("/revenue_sources");
          }

          if (
            applaudStore.isOnlyClassicAvailable &&
            userStore.me?.status !== Status.new &&
            userStore.me?.status !== Status.rejected
          ) {
            history.push("/transactions");
          }
        } catch (e) {
          appStore.isError = true;
          console.error(e);
        } finally {
          appStore.isLoadedMainInfo = true;
        }
      }
    })();
  }, [Auth.token]);

  return (
    <Router history={history}>
      <Route
        exact
        path="/"
        render={() => (
          <AuthRequired>
            <Home />
          </AuthRequired>
        )}
      />
      <Route
        path="/reporting-docs"
        render={() => (
          <AuthRequired>
            <ReportingDocuments />
          </AuthRequired>
        )}
      />
      <Route
        path="/transactions"
        render={() => (
          <AuthRequired>
            <Transactions />
          </AuthRequired>
        )}
      />
      <Route
        path="/onboarding"
        render={() => (
          <AuthRequired>
            <Onboarding />
          </AuthRequired>
        )}
      />
      <Route
        path="/connection"
        render={() => (
          <AuthRequired>
            <Connection />
          </AuthRequired>
        )}
      />
      <Route
        path="/expected-payments"
        render={() => (
          <AuthRequired>
            <ExpectedPayments />
          </AuthRequired>
        )}
      />
      <Route
        path="/revenue_sources"
        render={() => (
          <AuthRequired>
            <RevenueSources />
          </AuthRequired>
        )}
      />
      <Route
        path="/ao-list"
        render={() => (
          <AuthRequired>
            <Aolist />
          </AuthRequired>
        )}
      />
      <Route
        path="/reconciliationReport"
        render={() => (
          <AuthRequired>
            <ReconcilationReport />
          </AuthRequired>
        )}
      />
    </Router>
  );
}

export default observer(App);
