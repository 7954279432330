import { makeAutoObservable, reaction } from "mobx";
import { Currency } from "types";
import { authorizedRequest } from "../utils/authorization";
import dashboardStore, { Claim } from "./Dashboard";

// eslint-disable-next-line no-shadow
export enum Status {
  rejected = "rejected",
  new = "new",
  pending = "pending",
  signed = "signed",
  funded = "funded",
}

export const CURRENCY = {
  EUR: "EUR",
  USD: "USD",
};
export const PRODUCTS = {
  FRE: "FRE",
};

export type Record = {
  ao: string;
  company: String;
  currency: Currency;
  funded: String;
  id: string;
  issue_transaction_id: null;
  outstanding: null;
  purchase_date: null;
  purchase_price: string;
  repayment: null;
  request: number;
  status: Status;
  updated_dt: string;
};

type ClaimRecord = {
  advance_date: string;
  amount: string;
  available_receivables: string;
  currency: Currency;
  deleted: boolean;
  expected_date: string;
  id: string;
  name: string;
  number: number;
  icon: string;
  purchased: string;
  outstanding_amount: string;
  period_from: string;
  period_to: string;
};

type ApplaudData<R, C> = {
  available_balance: any;
  balance: any;
  purchase_requests: R[];
  claims: {
    limit: number | null;
    page: number;
    total: number;
    records: C[];
  };
};

type FutureListItem = {
  ao_number: string;
  id: string;
  purchase_date: null | string;
  purchase_price: string;
  purchased_receivables: string;
  purchased: string;
  outstanding_amount: string;
  currency: Currency;
};

export type AvailableProduct = {
  label: string;
  value: Currency | "FRE";
};

// eslint-disable-next-line no-shadow
export enum StatusClaim {
  REPAID = "repaid",
  FUNDED = "funded",
  REJECTED = "rejected",
}

type ClaimData = {
  sum_purchase_price: string;
  sum_purchased: string;
  claims: {
    records: null | ClaimRecord[];
    total: number;
  };
};

class Applaud {
  constructor() {
    makeAutoObservable(this);
  }

  data: ApplaudData<Record, ClaimRecord> | null = null;

  claims: ClaimData | null = null;

  statusClaim: StatusClaim = StatusClaim.FUNDED;

  futureReceivablesList: [] | FutureListItem[] = [];

  availableProducts: [] | AvailableProduct[] = [];

  claimsLoading: boolean = true;

  currencyByDefault: AvailableProduct | null = null;

  getApplaud = async () => {
    this.data = null;
    this.data = await authorizedRequest({
      url: "/ui/api/v1/applaud",
    });
  };

  getClaims = async () => {
    try {
      this.claimsLoading = true;
      this.claims = await authorizedRequest({
        url: "/ui/api/v1/claims",
        params: {
          // TODO changed https://gamesboost42.atlassian.net/browse/FCA-502?focusedCommentId=106913
          // pr_status: this.statusClaim,
          claim_status: this.statusClaim,
          currency: dashboardStore.fundingCurrency?.value || undefined,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.claimsLoading = false;
    }
  };

  getFutureReceivables = async () => {
    this.futureReceivablesList = await authorizedRequest({
      url: "/ui/api/v1/purchases/future",
      params: {
        currency: dashboardStore.fundingCurrency?.value || undefined,
        status: applaudStore.statusClaim,
      },
    });
  };

  get isClassicExist() {
    return this.availableProducts.find((item) => item.label === "CLASSIC");
  }

  getAvailableProducts = async () => {
    try {
      this.availableProducts = await authorizedRequest({
        url: "/ui/api/v1/available_products",
      });
      // eslint-disable-next-line no-nested-ternary
      this.availableProducts.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      this.currencyByDefault =
        this.availableProducts.find(
          (item) => item.label === "EUR" || item.label === "USD"
        ) || null;
    } catch (e) {
      console.error(e);
    }
  };

  get isUsdEurAvailable() {
    return (
      this.availableProducts.find((item) => item.value === "EUR") &&
      this.availableProducts.find((item) => item.value === "USD")
    );
  }

  get nowOrFuture(): Claim | null {
    if (this.availableProducts.some((item) => item.label.includes("NOW")))
      return "NOW";
    if (this.availableProducts.some((item) => item.label === "FUTURE"))
      return "FRE";
    return null;
  }

  get isNowAndFeatureAvailable() {
    return (
      this.availableProducts.some((item) => item.label.includes("NOW")) &&
      this.availableProducts.some((item) => item.label === "FUTURE")
    );
  }

  get isClassicAvailable() {
    return this.availableProducts.find((item) => item.label === "CLASSIC");
  }

  get isOnlyClassicAvailable() {
    return this.availableProducts.every((item) => item.label === "CLASSIC");
  }

  get isOnlyFutureAvailable() {
    return this.availableProducts.every((item) => item.label === "FUTURE");
  }
}

const applaudStore = new Applaud();

reaction(
  () => applaudStore.statusClaim,
  () => {
    if (dashboardStore.nowOrFuture === "FRE") {
      applaudStore.getFutureReceivables();
    } else if (dashboardStore.nowOrFuture === "NOW") {
      applaudStore.getClaims();
    }
  }
);
export default applaudStore;
