// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button as ButtonUK } from "@gamesb42/ui-kit";
import moment from "moment";

import Main from "layout/Main";
import SideMenu from "components/SideMenu";
import SalesInfo from "./SalesInfo";
import SendRequestModal from "./SalesInfo/components/SendRequestModal";
import platformStore from "./SalesInfo/store";
import { formatCurrency } from "../../utils/helpers";
import applaudStore, { Status } from "../../store/Applaud";
import Request from "./SalesInfo/components/Request";
import Tooltip from "../../components/Tooltip";
import EditRequest from "./SalesInfo/components/EditRequest";
import appStore from "../../store/App";
import Button, { ButtonMedium } from "../../components/Button";
import RevenueChart from "./RevenueChart";
import Skeleton from "components/Skeleton";
import connectSources from "../../store/ConnectSources";
import Connection, { InfoDrawer } from "../Connection";
import { about } from "../Connection/components/AboutCompany";
import Modal from "components/Modal";
import { BlockTitle } from "../../components/Text";
import classNames from "classnames";
import AnimatedList from "../../components/Animated/List";
import AnimatedContainer from "../../components/Animated/AnimatedContainer";
import User from "../../store/User";
import userStore from "../../store/User";
import isCompleteAllSteps from "pages/Connection/helper";

import { ReactComponent as CheckMark } from "./SalesInfo/assets/checkmark.svg";
import { ReactComponent as NewCheckMark } from "./assets/new-checkmark.svg";

import styles from "./styles.module.scss";

function Home() {
  return (
    <Main>
      <SideMenu />
      <div className={styles.container}>
        <Dashboard />
      </div>
    </Main>
  );
}

const Dashboard = observer(() => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showFundingModal, setShowFundingModal] = useState(false);

  useEffect(() => {
    platformStore.getPlatformsData();
  }, []);

  const printAmount = (amount: number | null | undefined) => {
    if (typeof amount === "number" && !platformStore.loadingData) {
      return `${formatCurrency(amount, User.getDefaultCurrency?.label)}`;
    }
    if (!platformStore.loadingData && typeof amount !== "number") return "N/A";
    return <Skeleton height={20} width={117} />;
  };

  // TODO sync condition with Connections
  const isNew =
    userStore.me?.status === Status.new ||
    userStore.me?.company?.status === Status.new;

  const isIE = about?.values?.details?.company_form === "sole-proprietor";

  if (
    //@ts-ignore
    ["in-review", "new"].includes(userStore.me?.company?.status)
  ) {
    // TODO revert
    // if (true) {
    return (
      <>
        <InfoDrawer initialScreen="infoScreen" />
        <Connection />
      </>
    );
  }

  const isInReviewStatusClient = userStore.me?.status === "in_review";
  const shouldShowAdvance =
    !applaudStore.isOnlyFutureAvailable && userStore.me?.status === "active";

  return (
    <>
      <InfoDrawer initialScreen="infoScreen" />
      <div className={styles.topDashboard}>
        <BlockTitle className={styles.mainTitle}>Dashboard</BlockTitle>
        <div className={styles.advanceBlock}>
          {shouldShowAdvance && (
            <>
              <div className={styles.advanceHolder}>
                <span className={styles.advanceTitle}>Available Advance:</span>
                <span className={styles.amount}>
                  {printAmount(+platformStore.data.available_advance)}
                </span>
                <Tooltip title="You can request us to transfer funds up to this amount to your bank account" />
              </div>
              <ButtonMedium
                onClick={() => {
                  setShowRequestModal(true);
                }}
              >
                Get Funding
              </ButtonMedium>
            </>
          )}
        </div>
      </div>
      {isNew && (
        <div className={classNames(styles.block, styles.blockComplete)}>
          <div>
            <div className={styles.emptyTitle}>
              Finish to complete your data and receive your first funding
            </div>
            <div className={styles.emptySubtitle}>
              We will check the data and send you contracts for signature.
            </div>
          </div>
          <div className={styles.arrow} />
        </div>
      )}
      {isInReviewStatusClient && (
        <div className={classNames(styles.block, styles.blockReview)}>
          <div className={styles.emptyTitle}>
            Thanks for your efforts! Your data{" "}
            <span className={styles.positive}>
              has been successfully collected
            </span>
          </div>
          <div
            className={classNames(
              styles.emptySubtitle,
              styles.emptySubtitleReview
            )}
          >
            Please allow us 48 hrs to verify information provided. Once it's
            verified, you can proceed with your funding request.
          </div>
        </div>
      )}
      {!applaudStore.isOnlyFutureAvailable && (
        <AnimatedContainer animation="zoomIn">
          <RevenueChart />
        </AnimatedContainer>
      )}

      {applaudStore.data?.purchase_requests?.map((request, idx) => {
        if (
          request.status === "rejected" &&
          -7 > moment(request.updated_dt).diff(moment.now(), "days")
        ) {
          return <></>;
        }

        return (
          <AnimatedList key={request.id} idx={idx}>
            <Request
              status={request.status}
              purchase_price={request.purchase_price}
              request={request.request}
              id={request.id}
            />
          </AnimatedList>
        );
      })}

      <AnimatedContainer animation="zoomIn" delay={100}>
        <SalesInfo />
      </AnimatedContainer>

      {showRequestModal && (
        <SendRequestModal
          isOpen={showRequestModal}
          onClose={() => {
            setShowRequestModal(false);
            platformStore.errorOnCreate = null;
            platformStore.validationError = null;
          }}
          setShowFundingModal={setShowFundingModal}
        />
      )}

      {about.isModalFillShowed && (
        <SuccessFillingModal
          onClose={() => {
            about.isModalFillShowed = false;
          }}
        />
      )}

      {appStore.requestId && (
        <EditRequest
          onClose={() => {
            appStore.requestId = null;
          }}
          id={appStore.requestId}
        />
      )}

      {showFundingModal && (
        <SuccessGetFundingModal
          isOpen={showFundingModal}
          onClose={() => {
            setShowFundingModal(false);
          }}
        />
      )}
    </>
  );
});

const SuccessFillingModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal isOpen>
      <div className={styles.modalContainer}>
        <div className={styles.grayHolder}>
          <CheckMark />
          <div className={styles.title}>
            Thanks for your efforts!
            <br />
            Your data <span>has been successfully collected</span>
          </div>
          <div className={styles.modalDescription}>
            Please allow us 48 hrs to verify information provided.
            <br />
            Once it's verified, you can proceed with your funding request.
          </div>
        </div>
        <Button
          type="cancel"
          onClick={onClose}
          className={styles.btnSendRequest}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

const SuccessGetFundingModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen}>
      <div className={styles.fundingModal}>
        <div className={styles.infoBlock}>
          <NewCheckMark />
          <div className={styles.header}>
            Request No{platformStore.requestCreated} was created successful
          </div>
          <div className={styles.text}>
            Link for signature was sent to {about?.signer?.email}
          </div>
        </div>
        <ButtonUK
          onClick={onClose}
          style={{ alignSelf: "flex-end", width: "132px" }}
        >
          Got it
        </ButtonUK>
      </div>
    </Modal>
  );
};

export default observer(Home);
