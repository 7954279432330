import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { formatCurrency, formatNumber, prettyDate } from "utils/helpers";
import Table, { Header, Body } from "components/Table";
import applaudStore from "../../store/Applaud";
import styles from "./styles.module.scss";

function FutureReceivables() {
  useEffect(() => {
    applaudStore.getFutureReceivables();
  }, []);
  return (
    <>
      {/*<TableContainer>*/}
      {/*  <Table stickyHeader aria-label="sticky table">*/}
      {/*    <TableHead className={classes.head}>*/}
      {/*      <TableRow>*/}
      {/*        <TableCell className={classes.head}>*/}
      {/*          No.*/}
      {/*        </TableCell>*/}
      {/*        <TableCell align="center" className={classes.head}>*/}
      {/*          You sold*/}
      {/*        </TableCell>*/}
      {/*        <TableCell align="center" className={classes.head}>*/}
      {/*          You got*/}
      {/*        </TableCell>*/}
      {/*        <TableCell align="center" className={classes.head}>*/}
      {/*          Purchase Date*/}
      {/*        </TableCell>*/}
      {/*      </TableRow>*/}
      {/*    </TableHead>*/}
      {/*    <TableBody>*/}
      {/*      {*/}
      {/*        applaudStore.futureReceivablesList.map(product => {*/}
      {/*          return (*/}
      {/*            <TableRow className={classes.columnRow}>*/}
      {/*              <TableCell className={classes.cell}>{product.ao_number}</TableCell>*/}
      {/*              <TableCell align="center" className={classes.cell}>{formatNumber(+product.purchased_receivables, 0, 3, ',')}</TableCell>*/}
      {/*              <TableCell align="center" className={classes.cell}>{formatNumber(+product.purchase_price, 0, 3, ',')}</TableCell>*/}
      {/*              <TableCell align="center" className={classes.cell}>{product.purchase_date && prettyDate(product.purchase_date) || '-'}</TableCell>*/}
      {/*            </TableRow>*/}
      {/*          );*/}
      {/*        })*/}
      {/*      }*/}
      {/*    </TableBody>*/}
      {/*  </Table>*/}
      {/*</TableContainer>*/}

      {/*<div className={classes.whiteContainer}>*/}
      {/*  <Box display="flex" alignItems="end">*/}
      {/*    <Back /><h3 className={classes.title}>Activation Order 12</h3>*/}
      {/*  </Box>*/}
      {/*  <div className={classes.divider} />*/}
      {/*  <Box display="flex">*/}
      {/*    <Box width="30%" display="flex">*/}
      {/*      <span className={classes.text}>Purchase price:</span><span className={classes.price}>€72,000.00</span>*/}
      {/*    </Box>*/}
      {/*    <Box width="30%" display="flex">*/}
      {/*      <span className={classes.text}>Outstanding:</span><span className={classes.price}>€72,000.00</span>*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*</div>*/}

      {/*<div className={classes.whiteHolder}>*/}
      {/*  <h4 className={classes.titleTable}>Repayment Schedule</h4>*/}
      {/*  <Box display="flex">*/}
      {/*    <Box width="20%" display="flex" alignItems="baseline"><span className={classes.titleHeader}>Repayment Date</span><Tooltip title="The marketplace or ad network which revenue you sell to us" /></Box>*/}
      {/*    <Box width="20%" textAlign="right"><span className={classes.titleHeader}>Repayment Amount</span><Tooltip title="The amount of revenue you have sold to us" /></Box>*/}
      {/*    <Box width="60%" textAlign="right"><span className={classes.titleHeader}>Status</span><Tooltip title="The amount we have transferred to your bank account" /></Box>*/}
      {/*  </Box>*/}
      {/*  <div className={classes.divider} />*/}
      {/*  <Box display="flex" height={52} alignItems="center">*/}
      {/*    <Box width="20%" display="flex" alignItems="center">*/}
      {/*      <span className={classes.cellText}>*/}
      {/*        21.02.2022*/}
      {/*      </span>*/}
      {/*    </Box>*/}
      {/*    <Box width="20%" textAlign="right"><span className={classes.cellText}>{getCurrencySymbol('EUR')}{formatNumber(123, 0, 3, ',')}</span></Box>*/}
      {/*    <Box width="60%" textAlign="right">123</Box>*/}
      {/*  </Box>*/}
      {/*</div>*/}
      <Table>
        <Header className={styles.tableSize}>
          <div>No.</div>
          <div>You sold</div>
          <div>You got</div>
          <div>Purchase Date</div>
          <div>Outstanding</div>
        </Header>
        <Body className={styles.tableSize}>
          {applaudStore.futureReceivablesList.map((product) => {
            return (
              <>
                <div>{product.ao_number}</div>
                <div>
                  {formatCurrency(
                    +product.purchased_receivables,
                    product.currency
                  )}
                </div>
                <div>
                  {formatCurrency(+product.purchase_price, product.currency)}
                </div>
                <div>
                  {(product.purchase_date &&
                    prettyDate(product.purchase_date)) ||
                    "-"}
                </div>
                <div>
                  {formatCurrency(
                    +product.outstanding_amount,
                    product.currency
                  )}
                </div>
              </>
            );
          })}
        </Body>
      </Table>
    </>
  );
}

export default observer(FutureReceivables);
