import axios from "axios";
import appStore from "store/App";
import Auth from "store/Auth";

export const authorizedRequest = ({
  url,
  method = "GET",
  data,
  params = {},
  service,
  forcedToken,
  responseType,
}: {
  url: string;
  method?: string;
  data?: object;
  params?: { [key: string]: string | string[] | null | undefined };
  service?: "pds" | "transactions" | "fca";
  forcedToken?: string;
  responseType?: string;
}) => {
  const getToken = () => forcedToken || Auth.scaToken;
  let baseUrl: string = "";
  if (!service) {
    baseUrl = `${process.env.REACT_APP_URL}`;
  } else if (service) {
    if (service === "pds") baseUrl = process.env.REACT_APP_PDS_URL || "";
    if (service === "transactions") {
      baseUrl = process.env.REACT_APP_TS_URL || "";
    }
    if (service === "fca") baseUrl = process.env.REACT_APP_SCA_URL || "";
  }

  return axios(`${baseUrl}${url}`, {
    method,
    data,
    params,
    // @ts-ignore
    responseType,
    mode: "CORS",
    headers: {
      authorization: `Bearer ${getToken()}`,
      "content-type": "application/json",
    },
  }).then(async (res) => {
    //@ts-ignore
    if (res?.code === "ERR_BAD_REQUEST") {
      appStore.setNotification({
        type: "error",
        title: `Please try once again.`,
        width: 380,
      });
    }
    return res.data;
  });
};

function createBaseAuthResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && ![401, 403].includes(error.response.status)) {
        return error;
      }

      if (error.request && !error.response) {
        return error;
      }

      if (!error.request && !error.response) {
        return error;
      }

      if (error.response && [401, 403].includes(error.response.status)) {
        axios.interceptors.response.eject(interceptor);

        return Auth.updateToken()
          .then(() => {
            error.response.config.headers.authorization = `Bearer ${Auth.scaToken}`;

            return axios(error.response.config);
          })
          .catch(() => {
            Auth.authorize();
          })
          .finally(createBaseAuthResponseInterceptor);
      }

      return error;
    }
  );
}

createBaseAuthResponseInterceptor();
