//@ts-nocheck
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  DownloadIcon,
  RangePicker,
  DatePickerProps,
  Select,
} from "@gamesb42/ui-kit";
import moment from "moment";

import Main from "../../layout/Main";
import SideMenu from "../../components/SideMenu";
import Auth from "store/Auth";
import { downloadFile } from "utils/helpers";

import s from "./styles.module.scss";

const ReconcilationReport = observer(() => {
  const [period, setPeriod] = useState<DatePickerProps["value"]>(undefined);
  const [loading, setLoadin] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [contracts, setContracts] = useState([]);

  // console.log("-->", documentId);

  const handleSubmitButtonClick = async () => {
    if (!loading && period && documentId.length > 0) {
      setLoadin(true);
      const myHeaders = new Headers();
      myHeaders.append("authorization", `Bearer ${Auth.scaToken}`);

      const res = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/reconciliation_report?start_report_date=${moment(period[0]).format(
          "YYYY-MM-DD"
        )}&end_report_date=${moment(period[1]).format(
          "YYYY-MM-DD"
        )}&document_id=${documentId}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      const { report_file, report_title } = await res.json();

      if (report_file && res.status === 200) {
        const contentType = "application/pdf";
        // format === "pdf"
        // ? "application/pdf"
        // : "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        downloadFile(report_file, contentType, report_title);
        setLoadin(false);
        return;
      }
      setLoadin(false);
    }
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("authorization", `Bearer ${Auth.scaToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    // TODO check on dev value of REACT_APP_BASE_URL
    fetch(`${process.env.REACT_APP_BASE_URL}/contracts`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setContracts(result?.contracts);
        setDocumentId(result?.contracts[0]?.id);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Main>
      <SideMenu />
      <div className={s.container}>
        <div className={s.body}>
          <div className={s.title}>Reconciliation report</div>
          {contracts.length > 1 && (
            <div style={{ width: "100%" }}>
              <Select
                label="Agreement"
                size="large"
                onChange={(e) => {
                  setDocumentId(e);
                }}
              >
                {contracts.map((el) => (
                  <Select.Option value={el.id}>{el.name}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          <div className={s.dates}>
            <RangePicker
              //@ts-ignored
              value={period}
              onChange={(e) => {
                //@ts-ignored
                setPeriod(e);
              }}
              label="Select period*"
              size="large"
              style={{ width: "100%" }}
            />
          </div>
          <Button
            type="primary"
            style={{ cursor: "pointer" }}
            onClick={handleSubmitButtonClick}
            icon={<DownloadIcon size={16} />}
            loading={loading}
          >
            Download report
          </Button>
        </div>
      </div>
    </Main>
  );
});

export default ReconcilationReport;
