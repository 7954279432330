import { useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import Tooltip from "../Tooltip";
import applaudStore from "../../store/Applaud";
import { formatCurrency, prettyDate } from "../../utils/helpers";
import { Table } from "@gamesb42/ui-kit";

import s from "./styles.module.scss";

const NowFunding = () => {
  const columns = [
    {
      title: (
        <div className={s.header}>
          <span>Date</span>
          <Tooltip title="The date we have transferred funds to you" />
        </div>
      ),
      // dataIndex: "advance_date",
      minWidth: "91px",
      render: (_: any, record: any) =>
        record.advance_date ? (
          <div className={s.column}>
            {moment(record.advance_date, "YYYY-MM-DD").format("DD.MM.YY")}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>Revenue Source</span>
          <Tooltip title="The marketplace or ad network which revenue you sell to us" />
        </div>
      ),
      minWidth: "160px",
      render: (_: any, record: any) =>
        record.icon && record.name ? (
          <div className={s.column}>
            <img
              style={{ width: 20, height: 20, marginRight: 6 }}
              src={record.icon}
              alt={record.name}
            />
            {record.name}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>Period</span>
          <Tooltip title="The period" />
        </div>
      ),
      minWidth: "118px",
      render: (_: any, record: any) =>
        record.period_from && record.period_to ? (
          <div className={s.column}>
            {record.period_from} - {record.period_to}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>You sold</span>
          <Tooltip title="The amount of revenue you have sold to us" />
        </div>
      ),
      minWidth: "118px",
      render: (_: any, record: any) =>
        record.purchased && record.currency ? (
          <div className={s.column}>
            {formatCurrency(+record.purchased, record.currency)}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>You got</span>
          <Tooltip title="The amount we have transferred to your bank account" />
        </div>
      ),
      minWidth: "118px",
      render: (_: any, record: any) =>
        record.amount && record.currency ? (
          <div className={s.column}>
            {formatCurrency(+record.amount, record.currency)}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>Due date</span>
          <Tooltip title="The date when we expect to receive sold revenue from the relevant Revenue Source. If we don’t get it from RS, we expect you to cover it directly" />
        </div>
      ),
      minWidth: "118px",
      render: (_: any, record: any) =>
        record.expected_date ? (
          <div className={s.column}>
            {moment(record.expected_date, "YYYY-MM-DD").format("DD.MM.YY")}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>Outstanding</span>
          <Tooltip title="The outstanding amount of the sold revenue which we expect to receive from the relevant Revenue Source. If we don’t get it from RS, we expect you to cover it directly" />
        </div>
      ),
      minWidth: "118px",
      render: (_: any, record: any) =>
        record.outstanding_amount && record.currency ? (
          <div className={s.column}>
            {formatCurrency(+record.outstanding_amount, record.currency)}
          </div>
        ) : (
          ""
        ),
    },
    {
      title: (
        <div className={s.header}>
          <span className={s.header}>Req</span>
          <Tooltip title="The number of the Purchase Request under which we have transferred funds to you" />
        </div>
      ),
      render: (_: any, record: any) =>
        record.number ? <div className={s.column}>{record.number}</div> : "",
    },
  ];

  useEffect(() => {
    applaudStore.getClaims();
  }, []);

  return (
    <Table
      columns={columns}
      initialHeight="673px"
      style={{ height: "673px" }}
      //@ts-ignore
      dataSource={applaudStore.claims?.claims?.records}
    />
  );
};

export default observer(NowFunding);
